<template>
  <div class="login-box">
    <img class="logo" src="../../assets/img/logo.png" alt="">
    <h1 class="login-title">智慧路灯平台</h1>
    <div class="login-from">
      <van-form label-width="4rem">
        <van-field v-model="loginData.username" name="用户名" label="账号" placeholder="请输入账号" />
        <van-field v-model="loginData.password" type="password" name="密码" label="密码" placeholder="请输入密码" />
        <div style="margin: 16px;">
          <van-button @click="onSubmit" round block type="primary">登录</van-button>
        </div>
      </van-form>
    </div>
  </div>
</template>
<script setup>
import { reactive } from "vue";
import { showToast } from "vant";
import router from "@/router/index";
let loginData = reactive({
  username: "",
  password: "",
});
function onSubmit() {
  if (loginData.username && loginData.username) {
    console.log(router);
    router.push({
      name: "Home",
    });
  } else {
    showToast({
      message: "请完善信息!",
      position: "top",
    });
  }
}
</script>
<style lang="less" scoped>
.login-box {
  position: relative;
  width: 100%;
  height: 100%;
  background-image: url("../../assets/img/loginbj.jpg");
  background-size: 100% 30rem;
  background-repeat: no-repeat;
  .logo {
    position: absolute;
    top: 6rem;
    left: 50%;
    width: 18rem;
    margin-left: -9rem;
  }
}
.login-title {
  position: absolute;
  top: 12rem;
  font-size: 3rem;
  width: 100%;
  font-weight: 600;
  color: #fff;
  text-align: center;
}
.login-from {
  position: absolute;
  top: 20rem;
  left: 50%;
  margin-left: -15rem;
  width: 30rem;
  background: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.4);
  border-radius: 1rem;
  padding: 1rem;
  box-sizing: border-box;
}
</style>