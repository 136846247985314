import {
  createRouter,
  createWebHashHistory
} from 'vue-router'
import Login from "@/views/login/login"
import Home from "@/views/home/home"

const routerHistory = createWebHashHistory()
const routes = [{
  path: '/',
  name: 'Home',
  component: Home,
}, {
  path: '/',
  name: 'Login',
  component: Login,
}, ]
const router = createRouter({
  history: routerHistory,
  routes
})
export default router