import AMap from "AMap";
import lamp from "../assets/img/lamp.png"
let DoovMap = function (dom, option, render) {
  let map = new AMap.Map(dom, option);
  this.map = map
  map.on("complete", function () {
    render && render()
  });
  this.markers = {} //地图所有的marker
  return this
}
DoovMap.prototype = {
  // option{
  //   lable:Boolean  是否需要名字 默认 true
  //   click:Fn       单击marker的回调
  //   dragend:Fn     拖拽的回调
  //   img:import lamp from "../assets/img/lamp.png"    自定义图标
  // }
  addMarker(option) {
    let arr = option.data
    for (let i of arr) {
      //图标
      let icon = new AMap.Icon({
        size: new AMap.Size(30, 36),
        image: option.img || lamp,
        imageSize: new AMap.Size(30, 36),
      });
      //marker
      let marker = new AMap.Marker({
        position: new AMap.LngLat(i.lng, i.lat),
        icon: icon,
        offset: new AMap.Pixel(-15, -30),
        draggable: option.dragend ? true : false,
        //自定义属性
        extData: {
          id: i.id
        }
      });
      //名字
      marker.setLabel({
        direction: 'bottom',
        content: `<div class='marker-lable'>${i.lable}</div>`, //设置文本标注内容
      });
      if (option.click) {
        //单击
        marker.on("click", (e) => {
          let obj = e.target.getExtData()
          option.click(obj)
        })
      }
      if (option.dragend) {
        //拖动
        marker.on("dragend", (e) => {
          let obj = e.target.getExtData()
          console.log(obj)
        })
      }
      //测试点的位置
      // let marker1 = new AMap.Marker({
      //   position: new AMap.LngLat(i.lng, i.lat),
      // });
      this.markers[i.id] = marker
      this.map.add(marker)
    }
  },
  getLoction(option) {
    AMap.plugin("AMap.Geolocation", () => {
      if (this.geolocation) {
        this.map.removeControl(this.geolocation)
      }
      if (this.markers['my']) {
        this.map.remove(this.markers['my'])
        delete this.markers['my']
      }
      let map = this.map,
        that = this;
      let geolocation = new AMap.Geolocation({
        enableHighAccuracy: true,
        timeout: 10000,
        zoomToAccuracy: true,
        showButton: false, //是否显示定位按钮
        showMarker: false, //定位成功时是否在定位位置显示一个Marker
        showCircle: false
      });
      map.addControl(geolocation);
      geolocation.getCurrentPosition(function (status, result) {
        if (status == "complete") {
          onComplete(result);
        } else {
          alert("定位失败!");
        }
      });
      // web端测试数据
      // onComplete({
      //   status: 0,
      //   position: [104.730485, 28.81501]
      // })
      function onComplete(data) {
        if (data.status == 0) {
          let arr = JSON.parse(JSON.stringify(data.position));
          // 定位偏移 纠正
          let point = new AMap.LngLat(arr[0] + 0.0024, arr[1] - 0.0029),
            marker = new AMap.Marker({
              position: point,
              draggable: true
            });
          map.setCenter(point)
          map.add(marker)
          //拖动
          marker.on("dragend", (e) => {
            let point = e.target.getPosition()
            option.mousemove && option.mousemove(point)
          })
          that.markers['my'] = marker
          option.render && option.render(point)
        }
      }
    });
  },
}
export default DoovMap