<template>
  <div class="content-box">
    <router-view></router-view>
  </div>
</template>

<script>
</script>

<style>
html,
body,
#app,
.content-box {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
</style>
