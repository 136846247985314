<template>
  <div class="map-box" id="container">
  </div>
</template>
<script setup>
import { nextTick } from "vue";
import DoovMap from "@/utils/map.js";
let doovMap = "";
nextTick(() => {
  // https://lighth5.dooviot.cn/
  doovMap = new DoovMap(
    "container",
    {
      viewMode: "2D", // 默认使用 2D 模式，如果希望使用带有俯仰角的 3D 模式，请设置 viewMode: '3D',
      zoom: 15, //初始化地图层级
    },
    () => {
      let arr = [
        {
          lng: 104.643894,
          lat: 28.774947,
          lable: "路灯1",
          id: 1,
        },
        {
          lng: 104.662004,
          lat: 28.774797,
          lable: "路灯2",
          id: 2,
        },
        {
          lng: 104.625612,
          lat: 28.767349,
          lable: "路灯3",
          id: 3,
        },
        {
          lng: 104.646039,
          lat: 28.766295,
          lable: "路灯4",
          id: 4,
        },
        {
          lng: 104.635053,
          lat: 28.759824,
          lable: "路灯5",
          id: 5,
        },
        {
          lng: 104.653936,
          lat: 28.760426,
          lable: "路灯6",
          id: 6,
        },
      ];
      //添加marker点
      doovMap.addMarker({
        data: arr,
        click: (obj) => {
          console.log(obj);
        },
      });
      // //获取手机当前定位信息
      doovMap.getLoction({
        render: (point) => {
          console.log(point);
        },
        mousemove: (point) => {
          console.log(point);
        },
      });
    }
  );
});
</script>
<style lang="less" scoped>
.map-box {
  position: relative;
  width: 100%;
  height: 100%;
}
</style>